import LocalizedStrings from 'react-localization';

let localizedData = new LocalizedStrings({
	 EN:{
	   "page-1":"Instructions",
	   beginTypingName: "Begin typing a name to make a selection",
	   instruction_line_1: "Welcome to Akeso Occupational Health",
	   instruction_line_2: "You are now checked-in. Please be sure to complete any required forms provided by our Customer Service Specialists.",
	   instruction_line_3:  "Your feedback helps us provide better service to you. As you proceed through your appointment, let us know how we are doing along the way and how we do overall.",
	   employer_instruction_line_3: "Your feedback helps us provide better service to you and your employees.  This is a short request for your feedback that asks you how we have been doing.  Providing you and your employees with the best experience is very important to us.",
	   intake_instruction_line_1: "Please begin your check-in by completing the following Infection Control Screening.",
	   intake_choose_badges: "Choose all badges that you are experiencing or select No Symptoms to proceed:",
	   checkin_complete: "CHECK-IN COMPLETE.",
	   intake_result: "Your Infection Control Screening was submitted with",
	   intake_badges:"symptoms",
	   nps_choose:"Choose one of the above",
	   please_select_day:"Please select a day",
	   "page-2":"Who did you see?",
	   notsure: "I'm not sure",
	   intake_no_symptoms:"No Symptoms",
	   next:"Next",
	   back:"Back",
	   confirmOkay: "Okay",
	   lowest:"Lowest",
	   highest:"Highest",
	   your_name:"Your Name:",
	   who_you_saw: "Who You Saw:",
	   where_you_were: "Where You Were:",
	   spinWheel:"Spin For Raffle Entries",
	   "page-3":"Which departments did you see?",
	   "page-4":"How did we do overall?",
	   "page-5":"Thank you!",
	   "page-6":"Thank you!",
	   demo_page_1: "How did we do overall?",
	   demo_page_2: "When were you here?",
	   demo_page_3: "Which departments did you see?",
	   demo_page_4: "Who did you see?",
	   how_was:"How was our ",
	   how_were:"How were our ",
	   how_did:"How did {0} do?",
	   review_our_location:"Review Our Location",
	   review_on_google:"Review {0} on Google",
	   reviewPromptText:"Please take one more minute to review us on sites like Google by clicking the icon below:",
	   reviewPromptTextLower:"We strive to exceed your expectations on every visit. If we failed to do so today, we are deeply sorry. A supervisor will be in contact with you.  We appreciate the chance to make things right. If, at that time, we have still been unable to resolve your concerns, you may leave additional feedback here: ",
	   badge_instruction_dept:"Choose the badges below that best describe your experience with this department.",
	   badge_instruction_staff:"Choose the badges below that best describe your experience with this staff member.",
	   badge_instruction_rep:"Choose the badges below that best describe the actions that need to be taken.",
	   write_feedback:"Write Feedback",
	   feedback_placeholder:"Have something else to say?  Let us know here...",
	   choose_badges:"Choose Badges",
	   endorsements:"Endorsements",
	   scroll_for_more:"scroll for more",
	   name:"Name",
	   name_placeholder:"Enter your name",
	   phone:"Phone",
	   phone_placeholder:"(###) ###-####",
	   email:"Email",
	   email_placeholder:"your@email.com",
	   submit:"Submit",
	   thank_you:"Thank You!",
	   completed_survey: "Thank you for completing our survey.",
	   recent_survey_text:"You recently completed a survey for",
	   would_you_like:"Would you like to:",
	   submit_new_survey:"Submit a new survey",
	   add_contact_info:"Add your contact information to the last survey",
	   get_info_text:"We appreciate your feedback. Leave your information and you will be entered into our monthly prize drawing.",
	   i_am_a:"I am a:",
	   patient:"Patient",
	   family_member:"Family Member",
	   care_giver:"Caregiver",
	   staff_member:"Staff Member",
	   attentive:"Attentive",
	   assistedme:"Assisted Me",
	   bestinthearea:"Best in the Area",
	   cleanoffice:"Clean Office",
	   comfortable:"Comfortable",
	   explainedclearly:"Explained Clearly",
	   forkids:"Great with Kids",
	   friendly:"Friendly",
	   gentletouch:"Gentle Touch",
	   knowledgeable:"Knowledgeable",
	   lovethem:"Love Them",
	   ontime:"On Time",
	   reviewedmeds:"Reviewed My Information",
	   royalservice:"Great Service",
	   speedy:"Fast Service",
	   tenoutoften:"Ten out of Ten",
	   greatvibe: "Great Ambiance",
	   accomodating: "Accommodating",
	   topnotch: "Top Notch",
	   greatattitude: "Great Attitude",
	   professional: "Professional",
	   bestofthebest: "Best of the Best",
	   warmgreeting: "Warm Greeting",
	   compassionate: "Compassionate",
	   welcoming:"Welcoming",
	   you_won:"You won: ",
	   quickservice: "Quick Service",
	   superstar:"Superstar",
	   informative:"Informative",
	   useful:"Useful",
	   entertaining:"Entertaining",
	   asnooze:"A Snooze",
	   interactive:"Interactive",
	   inspiring:"Inspiring",
	   average:"Average",
	   relevant:"Relevant",
	   relaxing:"Relaxing",
	   cool:"Cool",
	   greatchoice:"Great Choice",
	   fun:"Fun",
	   exceededexpectations: "Exceeded Expectations",
	   another_device:"This survey was started on another network.  Press the button below to start.",
	   spin: "Spin",
	   start: "Start",
	   viewrules: "View Rules",
	   their_name_placeholder: "Their name",
	   their_location_placeholder: "The location",
	   choose_team: "Choose teams to contact:",
	   choose_specific: "Choose specific actions ",
	   confirmNo: "No",
	   confirmYes: "Yes",
	   confirmRating: "Confirm Rating",
	   confirmSentence: "Are you sure you want to leave a ",
	   confirmSentenceTwo: " out of ",
	   confirmHRSentence:"Are you sure you would like to submit this feedback",
	   confirmHRFeedback: "Confirm Feedback",
	   recognizeByName: "Recognize By Name:",
	   search: "Search...",
	   howToFeedback: "How would you like to provide your feedback today?",
	   searchByName: "Search by Name",
	   searchByDept: "Search by Department",
	   findByDeptTeam: "Find by Department & Team",
	   recognitionStatement: "Choose who you would like to recognize.",
	   forgotSomeone:"Forgot Someone?",
	   pointBalance: "Your Point Balance:",
	   leaveMoreFeedback: "Leave more feedback below.",
	   recognitionMatters: "Recognition Matters"
	 },
	 ES: {
	 	confirmNo: "No",
	 	beginTypingName: "Comience a escribir un nombre para hacer una selección",
	   confirmYes: "Sí",
	   lowest:"Bajo",
	   highest:"Alto",
	   confirmOkay: "Bueno",
	   intake_badges:"síntomas",
	   pointBalance: "Su saldo de puntos:",
	   confirmRating: "Confirmar calificación",
	   confirmSentence: "¿Estás seguro de que quieres dejar un ",
	   confirmSentenceTwo: " de cada ",
	   confirmHRSentence:"¿Está seguro de que desea enviar este comentario?",
	   confirmHRFeedback: "Confirmar Comentarios",
	   recognizeByName: "Reconocer por nombre:",
	   search: "Buscar...",
	   leaveMoreFeedback: "Deja más comentarios a continuación.",
	 	searchByName: "Buscar por Nombre",
	    searchByDept: "Búsqueda por Departamento",
	    recognitionStatement: "Elige a quién te gustaría reconocer.",
	    findByDeptTeam: "Encontrar por departamento y equipo",
	 	howToFeedback: "¿Cómo te gustaría enviar tus comentarios hoy?",
	 	forgotSomeone:"¿Olvidaste a alguien?",	
	 	intake_no_symptoms:"Sin síntomas",   
	 	recognitionMatters: "Reconocimiento Importa",
	 	choose_specific: "Elija acciones específicas ",
	 	choose_team: "Elige equipos para contactar:",
	   their_name_placeholder: "Su nombre",
	   their_location_placeholder: "La locación",
	   "page-1":"Instrucciones",
	   intake_instruction_line_1: "Comience su registro completando la siguiente evaluación de detección de control de infecciones.",
	   intake_choose_badges: "Elija todas las insignias que está experimentando o seleccione Sin síntomas para continuar:",
	   checkin_complete: "ENTRADA COMPLETA.",
	   intake_result: "Su prueba de detección de detección de control de infecciones se envió con",
	   instruction_line_1: "Bienvenido Akeso Occupational Health",
	   instruction_line_2: "Ahora estás registrado. Asegúrese de completar todos los formularios requeridos proporcionados por nuestros Especialistas de Servicio al Cliente.",
	   instruction_line_3:  "Sus comentarios nos ayudan a brindarle un mejor servicio. A medida que avanza en su cita, háganos saber cómo lo estamos haciendo en el camino y cómo lo hacemos en general.",
	   employer_instruction_line_3: "Sus comentarios nos ayudan a brindar un mejor servicio a usted y a sus empleados. Esta es una breve solicitud de comentarios que le pregunta cómo nos ha ido. Brindarle a usted y a sus empleados la mejor experiencia es muy importante para nosotros.",
	   nps_choose:"Elige uno de los anteriores",
	   professional: "Profesional",
	   please_select_day:"Por favor seleccione un dia",
	   "page-2":"¿A quien visitó?",
	   notsure: "No estoy seguro",
	   your_name:"Tu Nombre:",
	   who_you_saw: "A Quien Viste:",
	   where_you_were: "Donde Estabas:",
	   next:"Siguiente",
	   back:"Regresa",
	   you_won:"Ganaste: ",
	   viewrules: "Ver Reglas",
	   spinWheel:"Girar para las entradas de la rifa",
	   "page-3":"¿Qué departamentos visitaste?",
	   "page-4":"¿Cómo lo hicimos en general?",
	   "page-5":"Gracias!",
	   demo_page_1: "¿Cómo lo hicimos en general?",
	   demo_page_2: "¿Cuando estabas aqui?",
	   demo_page_3: "¿Qué departamentos visitaste?",
	   demo_page_4: "¿A quien visitó?",
	   how_was:"¿Cómo fue nuestra ",
	   how_were:"¿Cómo fue nuestras ",
	   how_did:"¿Cómo hizo {0}?",
	   review_our_location:"Revise nuestra ubicación",
	   review_on_google:"Revisar {0} en Google",
	   badge_instruction_dept:"Elija las insignias a continuación que mejor describan su experiencia con este departamento.",
	   badge_instruction_staff:"Elija las insignias a continuación que mejor describan su experiencia con este miembro del personal.",
	   badge_instruction_rep:"Elija las insignias a continuación que mejor describan las acciones que deben tomarse.",
	   write_feedback:"Escribir Comentarios",
	   feedback_placeholder:"¿Tienes algo más que decir? Háganos saber aquí...",
	   choose_badges:"Elegir insignias",
	   endorsements:"Insignias",
	   scroll_for_more:"Desplácese por más",
	   reviewPromptText:"Tómese un minuto más para revisarnos en Google o Yelp! haciendo clic en el siguiente icono:",
	   reviewPromptTextLower:"Nos esforzamos por superar sus expectativas en cada visita. Si no lo hicimos hoy, lo lamentamos profundamente. Un supervisor se pondrá en contacto con usted en breve. Apreciamos la oportunidad de hacer las cosas bien. Si, en ese momento, aún no hemos podido resolver sus inquietudes, puede dejar comentarios adicionales aquí.",
	   name:"Nombre",
	   spin:"Girar",
	   name_placeholder:"Introduzca su nombre",
	   phone:"Teléfono",
	   phone_placeholder:"(###) ###-####",
	   email:"Email",
	   email_placeholder:"tu@email.com",
	   submit:"Enviar",
	   thank_you:"Gracias!",
	   "page-6":"Gracias!",
	   recent_survey_text:"Recientemente completaste una encuesta para",
	   completed_survey: "Gracias por completar nuestra encuesta.",
	   would_you_like:"Te gustaria:",
	   submit_new_survey:"Enviar una nueva encuesta",
	   add_contact_info:"Agregue su información de contacto a la última encuesta",
	   get_info_text:"Agradecemos sus comentarios. Deje su información y será ingresado en nuestro sorteo de premios mensual.",
	   i_am_a:"Soy un:",
	   patient:"Paciente",
	   family_member:"Miembro de la familia",
	   care_giver:"Cuidador",
	   staff_member:"Miembro del equipo",
	   attentive:"Atento",
	   assistedme:"Me Ayudo",
	   bestinthearea:"Mejor en el Area",
	   cleanoffice:"Oficina Limpia",
	   comfortable:"Cómodo",
	   explainedclearly:"Explicado Claramente",
	   forkids:"Genial con los niños",
	   gentletouch:"Toque Gentil",
	   friendly:"Simpático",
	   knowledgeable:"Experto",
	   accomodating: "acomodado",
	   lovethem:"Amarlos",
	   ontime:"A tiempo",
	   start: "Comienzo",
	   bestofthebest: "Mejor de lo mejor",
	   reviewedmeds:"Revisado mi información",
	   royalservice:"Gran servicio",
	   speedy:"Servicio rápido",
	   tenoutoften:"Diez de diez",
	   welcoming:"Acogedor",
	   compassionate: "Compasivo",
	   quickservice: "Servicio rapido",
	   warmgreeting: "Cordial saludo",
	   greatvibe: "Buen ambiente",
	   topnotch: "De primera categoría",
	   greatattitude: "Gran actitud",
	   superstar:"Superestrella",
	   informative:"Informativo",
	   useful:"Útil",
	   entertaining:"Entretenido",
	   asnooze:"Una siesta",
	   interactive:"Interactivo",
	   inspiring:"Inspirador",
	   average:"Promedio",
	   relevant:"Pertinente",
	   relaxing:"Relajante",
	   cool:"Guay",
	   greatchoice:"Gran elección",
	   fun:"Divertido",
	   exceededexpectations: "Superó las expectativas",
	   another_device:"Esta encuesta se inició en otra red. Presione el botón de abajo para comenzar."
	 }
	});  		


export default localizedData;

